import { Component, Input, OnInit } from '@angular/core';
import { WoundCareOrder } from '../../lib/models/woundCareOrder.interface';
import { WoundCareOrderService } from '../../lib/wound-care-order.service';
import { LhcDataService } from '../../lib/lhc-data.service';
import { CommonUtilsService } from '../../lib/common-utils.service';
import { QUESTION_CODE } from 'src/lib/models/wound-measure.interface';

declare function sendMessageToFlutter(event, item): void;
@Component({
  selector: 'sm-wound-care-order-picker',
  templateUrl: './sm-wound-care-order-picker.component.html',
  styleUrls: ['./sm-wound-care-order-picker.component.css'],
})
export class SmWoundCareOrderPickerComponent implements OnInit {
  @Input() woundCareOrder: WoundCareOrder;
  @Input() item: any;
  woundCareOrderQuestionCode = QUESTION_CODE.woundCareOrderPicker;

  constructor(
    private lhcDataService: LhcDataService,
    private woundCareOrderService: WoundCareOrderService,
    public commonUtils: CommonUtilsService
  ) {}

  ngOnInit() {
    this.woundCareOrderService.getOrder().subscribe((value) => {
      this.woundCareOrder = value;
      this.setWoundCareOrderValue();
    });
  }

  goToSelectOrder(): void {
    sendMessageToFlutter('selectOrder', null);
  }

  private setWoundCareOrderValue(): void {
    let formWithData = this.lhcDataService.getLhcFormData();
    this.findForQuestionCodeAndSetValue(
      formWithData.items,
      QUESTION_CODE.woundCareOrderPicker
    );
    setTimeout(() => {
      this.lhcDataService.setLhcFormData(formWithData);
    });
  }

  private findForQuestionCodeAndSetValue(
    items: any[],
    questionCode: string
  ): string {
    const newValue = this.woundCareOrder?.description;
    for (const item of items) {
      if (item.questionCode === questionCode) {
        item.value = newValue;
        return item.value?.code ?? item.value;
      }
      if (item.items && item.items.length > 0) {
        const value = this.findForQuestionCodeAndSetValue(
          item.items,
          questionCode
        ); // Recursive call to review items inside an item
        if (value) {
          return value;
        }
      }
    }
    return ''; // Return empty string if value is not found
  }
}
