import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SmService {
  private showEvaluationSummary = false;

  constructor() {
    window['SmService'] = this;
  }

  setIsEvaluationSummaryView(showEvaluationSummary: boolean) {
    this.showEvaluationSummary = showEvaluationSummary;
  }

  getIsEvaluationSummaryView() {
    return this.showEvaluationSummary;
  }
}
