import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { LhcDataService } from '../../lib/lhc-data.service';
import { RulesEngineData } from 'src/lib/models/rulesEngineData.interface';

declare function showErrors(): boolean;
@Component({
  selector: 'sm-validate',
  templateUrl: './sm-validate.component.html',
  styleUrls: ['./sm-validate.component.css'],
})
export class SmValidateComponent implements OnChanges {
  @Input() item: any;
  @Input() value: any;
  errorsData: { error: string; code: string }[] = null;
  showErrorsData = false;

  constructor(private lhcDataService: LhcDataService) {}

  /**
   * Invokded when the properties change
   * @param changes changes.prop contains the old and the new value...
   */
  ngOnChanges(changes) {
    if (changes.value && !changes.value.firstChange) {
      let lfData = this.lhcDataService.getLhcFormData();
      this.hasErrors();
      // check validation on the item
      setTimeout(() => {
        lfData._checkValidations(this.item);
        if (this.item._validationErrors) {
          this.item._validationErrors.forEach((error) => {
            this.lhcDataService.sendMsgToScreenReader(
              `${this.item.question} ${error}`
            );
          });
        }
      }, 1);
    }
  }

  hasErrors() {
    const errors: RulesEngineData[] =
      this.lhcDataService.getRulesEngineResponse();
    if (errors) {
      this.errorsData = errors.filter((e) => e.code === this.item.questionCode);
    }
  }

  displayErrors() {
    return showErrors();
  }
}
